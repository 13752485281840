.contact-info{

	padding-left: 0;
	list-style: none;
	margin-top: 50px;

	li{
		@include font-bold;
		@include link-color(#4D4F53, $brand-color);
		padding: 10px 0;
		font-size: 1.5em;
	}

}

.img-container.portrait{

	height: auto;
	padding-bottom:0;

	img{
		position: relative;
		width: 100%;
		left: 0;
		top: 0;
		transform:translate(0, 0);
	}
}



.filter-nav{
	margin-top: 10px;
	list-style: none;
	padding-left: 0;

	li{
		display: inline-block;
		@include link-color(#ADAFAF, $brand-color);
		@include font-bold;
		&:not(:last-of-type){
			margin-right: 25px;
		}
		a.active{
			color: $brand-color;
			&:hover{
				color: #ADAFAF;
			}
		}
		
	}

}

#people-container{

	.person-tile{
		a{
			display: block;
			width: 100%;

			h4{
				color: #111;
				transition:color 0.2s ease-in-out;
			}
			&:hover{
				h4{
					color:$brand-color
				}
				.person-container{
					img{
						filter:grayscale(0%);
						opacity: 1;
					}
				}
			}
		}
		.text-container{
			opacity: 0.4;
			transition: opacity 0.35s cubic-bezier(0.95, 0.14, 0.51, 0.99);
			small{
				color: #111;
			}
			.contact-info{
				margin-top: 15px;
				li{
					padding-top: 0;
					font-size: 1.2em;
					line-height: 1em;
				}
			}
		}
		.person-container{
			position: relative;
			img{
				width: 100%;
				filter:grayscale(100%);
				opacity: 0.4;
				transition:filter 0.35s cubic-bezier(0.95, 0.14, 0.51, 0.99), opacity 0.35s cubic-bezier(0.95, 0.14, 0.51, 0.99);
			}
			.fa-plus{
				position: absolute;
				top: 15px;
				right: 18px;
				color: #fff;
				font-size: 3em;
				z-index: 2;
			}
		}
		&.active{
			.text-container{
				opacity: 0.8;
				transition: opacity 0.35s cubic-bezier(0.95, 0.14, 0.51, 0.99);
			}
			.person-container{
				img{
					opacity: 0.9;
				}
			}
		}

	}
}

@include media-breakpoint-down(sm) {

	.contact-info{
		margin-top:25px;
		li{
			line-height: 1em;
			font-size: 1.2em;
			padding-bottom: 0;
		} 
	}

}


