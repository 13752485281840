.card{
	margin-top: 50px;
	border: 0;
	background-color: transparent;
	margin-bottom: 50px;
	.lead{
		font-size: 18px;
		@include heading-bold;
	}
	.list-heading{
		color: $brand-color;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	.card-title{
		font-size: 24px;
		a{
			color: #000;
			&:hover{
				color: $brand-color;
			}
		}
	}
	.img-container {
		max-width: 100%;
		height: 0;
		overflow: hidden;
		position: relative;
		padding-bottom: 430px;
		margin-bottom: 20px;
		img{
			clip-path: url("#cardPath");
		}
	}
	.promo {
		display: flex;
		justify-content: center;
		text-align: center;
		.circle{
			width: 150px;
			height: 150px;
			border-radius: 100%;
			position: absolute;
			z-index: 1;
			margin-top: -50px;
			background-image: url("../assets/content/promo-bg.png");
			color: #fff;
			text-align: center;
			padding: 20px;
			span{
				@include heading-bold;
				line-height: 1;
				font-size: 40px;
				display: block;
				color: #fff;
			}
		}

	}

}

.project-card{
	padding-bottom: 50px;
	.img-container{
		margin-bottom: 30px;
		&:before {
			content: "";
			height: 100%;
			width: 100%;
			background-color: rgba(255, 255, 255, 0.4);
			position: absolute;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
	}

	a{
		&:hover{
			.img-container{
				&:after {
					opacity: 1;
				}
				&:before {
					opacity: 1;
				}
			}
		}
	}

	h2{
		a{
			color: #4D4F53;
			&:hover{
				color: $brand-color;
			}
		}
	}
}

.directory-filters{
	margin-top: -21px;
	background: $brand-color;
	padding: 15px 0;
	select{
		color: $brand-color;
		background: $brand-color;
		border:0;
		& + select{
			margin-left: 30px;
		}
	}
	& + .tint-bg{
		margin-top: 0;
	}
	.form-group{
		margin-bottom: 0;
		.form-control{
			background: transparent;
			border-radius: 0;
			border:0;
		}
		.input-group-addon{
			background: transparent;
			color: #fff;
			border:0;
		}
	}
}


.category-nav{
	list-style: none;
	padding-left: 0;
	margin: 30px 0 45px;
	padding-bottom: 20px;
	padding-left: 30px;
	border-bottom: 1px solid rgba(170, 165, 158, 0.30);
	position: relative;
	text-align: center;
	padding-bottom: 30px;
	li{
		display: inline-block;
		padding-bottom: 3px;
		margin-right: 20px;
		a{
			color: #AAA59E;
			@include font-bold;
			&:hover,
			&:focus{
				color: $brand-color;
			}
		}
		&.active{
			a{
				color: $brand-color;
				&:hover,
				&:focus{
					color: #AAA59E;
				}
			}

		}

		span{
			color: #b3b9b6;
			transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
	}
	a{
		color: $brand-color;

		&:hover{
			color: $brand-color;
			span{
				color: $brand-color;

			}
		}
	}

}
.directory-controls{
	position: absolute;
	right: 30px;
	top: 0;
	padding-top: 15px;
	a{
		font-size: 1.8em;
		background: $brand-color;
		color: #fff;
		border-radius: 50%;
		width: 48px;
		height: 48px;


		text-align: center;
		margin-right: 10px;
		display: flex;
		justify-content:center;
		align-items:center;
		float: left;
		transition:background-color 0.2s ease-in-out;
		&:hover,
		&:focus{
			background:$brand-color;
		}
	}
}


.media.document{
	margin-bottom: 50px;

	.media-left{
		width: 33%;
		display: block;
		float: left;
	}
	.media-body{
		width: 65%;

		display: block;
		float: left;
		padding-left: 15px;
		padding-bottom: 30px;
		position: relative;

		small{
			display: block;
			color: $brand-color;
			@include font-bold;
			margin-bottom: 10px;
		}
		h3{
			margin-bottom: 15px;
		}
	}

	.media-footer{
		overflow: hidden;
		width: calc(100% - 20px);
		position: absolute;
		display: flex;
		justify-content:space-between;
		bottom: 0;
		a + a{
			margin-left: 30px;
		}
		small{
			color: #999;
		}
	}
}

.project-filter{
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	li{
		display: inline-block;
		@include link-color(#ADAFAF, $brand-color);
		@include font-bold;
		&:not(:last-of-type){
			margin-right: 25px;
		}
		a.active{
			color: $brand-color;
			&:hover{
				color: #ADAFAF;
			}
		}
		
	}

}

@include media-breakpoint-down(md) {
	.card{
		.img-container {
			padding-bottom: 300px;
			img{
				clip-path: url("#cardPathMobile");
			}
		}

	}

}


