
.footer-quote{
	.datepicker{
		&.dropdown-menu{
			padding: 15px;
		}
	}
	+ footer{
		margin-top: 0;
	}
	margin-top: 50px;
	@include link-color(#ffffff, $brand-color);

	.mountian-top{
		width: 100%;
		text-align: center;
		margin-bottom: -5px;
	}

	&.shallow{
		.footer-content{
			padding: 30px;
			p{
				width: 100%;
				margin: 0 auto 30px;
			}
			p,h1,h2{
				color: #ffffff;
				text-align: left;
			}
		}
	}

	.footer-content{
		background: $brand-color;
		padding: 50px 50px;	
		border-radius: 5px 5px 0 0;
		p{
			width: 60%;
			margin: 0 auto 40px;
		}
		.form-group{
			margin-bottom: 10px;
		}
		.form-control {
			background-color: transparent;
			background-clip: padding-box;
			border: 1px solid #ffffff;
			opacity: 1;
			border-radius: 3px;
			height: calc(1.5em + .75rem + 8px);
			color: #fff;

			&::placeholder{
				color:#fff;
			}

			option{
				color:#000;
			}
		}
	}
	
	p,h1,h2{
		color: #ffffff;
		text-align: center;
	}

	h3{
		color: #fff;
		@include font-bold;
		margin-bottom: 15px;
		font-size: 1em;
	}
	hr{
		border-top: 1px solid #686968;
		margin: 40px 0;
	}
	
	.btn.btn-primary{
		background-color: #ffffff;
		color: #000;

	}
}


footer{
	margin-top: 2em;
	@include link-color(#ffffff, $brand-color);
	z-index: 20;
	position: relative;
	.mountian-bottom{
		margin-top: -60px;
		margin-bottom: -5px;
	}
	.footer-legal{
		padding: 0px 30px 30px;
		background: #000;
		display: block;
		ul{
			padding-left: 0;
			li{
				float: left;
				list-style: none;
				color: #ffffff;
				margin-right: 25px;
				&:nth-last-child(1){
					float: right;
					margin-right: 0;
				}

			}
		}
	}
	
	p,h1,h2{
		color: #ffffff;
		text-align: center;
	}

	h3{
		color: #fff;
		@include font-bold;
		margin-bottom: 15px;
		font-size: 1em;
	}
	hr{
		border-top: 1px solid #686968;
		margin: 40px 0;
	}
	
	.btn.btn-primary{
		background-color: #ffffff;
		color: #000;

	}
}

@include media-breakpoint-down(md) {

	.footer-enquire{
		.mountian-top{
			margin-top: -30px;
		}

	}
	.footer-quote{
		margin-top: 50px;

		&.shallow{
			.footer-content{ 
				padding: 30px 30px 60px 30px;
			}
		}
		.footer-content{
			p{
				width: 100%;
			}
		}
	}


	footer{
		.mountian-bottom{
			margin-top: -35px;

		}
		.information{
			p{
				width: 100%;
			}
			img{
				margin: 20px 0;
			}
		}
		
		.logo{
			img{
				margin-bottom: 20px;
			}
		}
		.legals{
			ul{
				display: block;
				li{
					display: block;
					margin-bottom: 5px;
				}
			}
		}
	}
}