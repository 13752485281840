.stars{
	padding-left: 0;
	margin-bottom: 20px;
	display: inline-block;
	li {
		list-style: none;
		float: left;
		margin-right: 5px;
	}
}

.text-center{
	.stars{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.review{
		.location-pin{
			justify-content: center;
		}
	}
}

.featured-review{

	.review{
		margin-bottom: 30px;
		.comment{
			font-size: 18px;
			font-style: italic;
		}
		.customer{
			@include heading-bold;
			font-size: 18px;
		}
	}


}

.review{
	margin-bottom: 30px;
	.comment{
		font-style: italic;
	}
	.customer{
		@include heading-bold;
	}

}

@include media-breakpoint-up(md) {
	.review{
		.location-pin {
			display: flex;
			align-items: center;
		}
	}

}

@include media-breakpoint-down(md) {

	.intro-image{
		margin-top: 50px;
	}

}