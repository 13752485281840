.section{
	position: relative;
	padding-top: 3em;
	padding-bottom: 3em;
	svg{
		display: block;
	}
	&.page-content{
		padding-top: 1em;
		padding-bottom: 1em;

		&:first-of-type{
			padding-top: 4em;
		}
		&:last-of-type{
			padding-bottom: 4em;
		}
	}

	.row + .row{
		margin-top: 75px;
	}

	.capability{
		padding-bottom: 50px;
	} 

	&.mountian-section{
		padding-top: 0em;
		padding-bottom: 0em;
		margin-top: -100px;
		position: relative;
		z-index: 3;

		svg:first-of-type{
			margin-bottom: -1px;
		}
	}
}

.rates-table{
	td, th {
    	width: 100px;
	}
}




#fullBanner{
	-webkit-transform:scale(.0006,.0016);
	transform:scale(.0006,.0016);
}

#heroBanner{
	-webkit-transform:scale(.0006,.0016);
	transform:scale(.0006,.0016);
}

#shallowBanner{
	-webkit-transform:scale(.0006,.0024);
	transform:scale(.0006,.0024);
}


#carouselClip{
	-webkit-transform:scale(.0006,.0013);
	transform: scale(.0006,.0013);
}



