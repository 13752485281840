
.green-bg{
	svg{
		fill:$brand-color;
	}
	.section-wrap{
		padding-top: 4em;
		padding-bottom: 4em;
		background: $brand-color;
		p,h1,h2,h3{
			color: #ffffff;
		}
		.btn{
			color: $brand-color;
			background-color: #fff;
		}
		svg{
			fill: $brand-color;
		}
		& ~ svg{
			fill: $brand-color;
		}
	}
}

.quote-page{
	.errors{
		//position: absolute;

		li {
			color: $error-color;
		}
	}

}

.quote-payment-step {
	.reservation-confirm__grid {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.reservation-confirm__grid-item {
			margin-bottom: 1rem;
		}

		.reservation-confirm__grid-item--full {
			grid-column: span 2;
		}

		.form-group {
			margin: 0;
			float: none;
		}
	}
}


@include media-breakpoint-down(md) {

	.section{
		padding-top: 30px;
		padding-bottom: 30px;

		.section-wrap{
			& ~ svg{
				margin-top: -5px;
			}
		}
		&:before{
			position: initial;
			display: inline-block;
			left: 0;
			margin-bottom: 10px;
		}
		& > .small{
			text-align: left;
			transform: none;
			margin-bottom: 20px;
			display: inline-block;
			margin-left: 20px;
		}
		.row + .row{
			margin-top: 30px;
		}
	}

	
}