3//- Change at your peril
.nav-trigger {
	display: none;

}

.header-wrap{
	position: relative;
	width: 100%;
	z-index: 99;
	left: 0;
}

.main-nav.navbar.navbar-default {
	background-color: #ffffff;
	border: 0 none transparent;
	margin-bottom: 0;
	.navbar-nav {
		margin-bottom: 0;
		.dropdown-toggle{
			&:after{
				margin-left: 4px;
				vertical-align: .1em;
			}
		}

		li {
			&>.active>a,
			&>.active>a:hover,
			&>.active>a:focus {
				background: transparent;
			}
		}
		.dropdown {
			position: relative;
			.close {
				display: none;
				position: absolute;
				opacity: 1;
				text-shadow: none;
				&:hover{
					color: $brand-color;
				}
			}
			&.open {
				.dropdown-menu {
					transform: translateX(0%);
				}

				&>a,
				& a:hover,
				& a:focus {
					background-color: transparent;
				}
			}
			.dropdown-menu {
				position: absolute;
				border: 0;
				border-top: 2px $brand-color solid;
				border-radius: 0 0 .25rem .25rem;
				margin-top: 10px;
				background-color: #000;

				& > li {
					
					&>a {
						padding: 10px 25px;
						white-space: nowrap;
						justify-content: left!important;
						&:focus,
						&:hover {

							text-decoration: none;
							background-color: transparent;
						}
					}
					&:hover {
						background-color: transparent;
						color: $brand-color;
					}
				}

			}
		}
	}
}


@media (max-width: 991px) {

	.navbar-brand {
		overflow: hidden;
		display: block;
		float: none;
		padding: 0;

	}

	.page-wrapper {
		position: relative;
		z-index: 2;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);

	}
	.main-nav.navbar.navbar-default {
		opacity: 0;
		visibility: hidden;
		transition: visibility 0.2s , opacity 0.2s ease-in-out;
		.navbar-nav {
			width: fit-content;
			li {
				float: none;
				&>a:hover,
				&>a:focus,
				&>a:hover:before,
				&>a:focus:before {
					color: $brand-highlight;
				}
			}
			.dropdown{


				.dropdown-menu {
					background-color: transparent;
					padding: 0 0 1em;
					position: relative;
					border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
					border-radius: 0;

					&>li {
						padding-left: 0;
						font-size: 16px;
						&>a {
							padding-left: 0;
							white-space: normal;
						}
					}
					&:before {
						content: '';
						display: block;
						position: absolute;

					}
					.close {
						display: block;
					}
				}
			}
		}
	}
	.navigation-is-open {
		overflow: hidden;

		.main-nav-trigger {
			transform: rotate(180deg);

		}

		.main-nav.navbar.navbar-default{
			visibility: visible;
			opacity: 1;
			transition:visibility 0s ease-in-out, opacity 0.2s ease-in-out;
		}
	}

	.main-nav {
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding: 0;

		.main-navigation-wrapper {
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}

	}

	.main-nav-trigger {
		position: fixed;
		z-index: 11;
		display: block;
		overflow: hidden;
		text-indent: 100%;
		background-color: $brand-color;
		padding: 23px;
		border-radius: 100%;

		.main-nav-icon {

			position: absolute;
			@include center;
			background-color: #ffffff;

			&::before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: inherit;
				transform: translateZ(0);
				background: #fff;
				backface-visibility: hidden;
				transition: transform 0.3s, width 0.3s, top .3s;
			}
			&::before {
				transform-origin: center;
				transform: translateY(-10px);
			}
			&::after {
				transform-origin: center;
				transform: translateY(10px);
			}
		}
		.no-touch &:hover .main-nav-icon::after {
			top: 2px;
		}
		.no-touch &:hover .main-nav-icon::before {
			top: -2px;
		}


		.navigation-is-open & {
			// rotate trigger when navigation becomes visible 
			transform: rotate(180deg);
			.main-nav-icon{
				background-color: transparent; 
			}
			.main-nav-icon::after,
			.main-nav-icon::before {
				// animate arrow --> from hamburger to arrow 

				transition: transform 0.5s;
				transition: width 0.5s;
			}
			.main-nav-icon::before {
				transform: rotate(45deg);

			}
			.main-nav-icon::after {
				transform: rotate(-45deg);

			}
			.no-touch &:hover .main-nav-icon::after,
			.no-touch &:hover .main-nav-icon::before {
				top: 0;
			}
		}
	}

	.header-wrap {
		z-index: inherit;
	}

}

@media (min-width: 768px){
	.navbar-nav {
		float: none; 
		margin: inherit; 
	}
}