
#carousel-gallery{
	.carousel-inner{
		border-radius: 10px;
		.carousel-item{
			height: 750px;
			.banner-container{
				img{
					position: absolute;
					height: 100%;
					width: 100%;
					max-width: none;
					transform: translate(-50%,-50%);
					left: 50%;
					top: 50%;
					-o-object-fit: cover;
					object-fit: cover;
				}	
			}
		}
	}
	
}



.carousel-svg{
	svg{
		margin-top: -45px;
		position: relative;
		z-index: 1;
		*{
			fill: $brand-color;
		}
	}
}


.thumb-carousel{
	margin-top: -10px;
	background-color: #154634;
	padding: 20px 35px 35px 35px;
	border-radius: 10px;
	position: relative;
	z-index: 2;
	.thumb-caption{
		color: #fff;
	}
	.thumb-container {
		cursor: pointer;
		height: 90px;
		width: 100%;
		img{
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

	}

	.owl-carousel{
		.owl-stage-outer {
			z-index: 3;
		}
	}
}

.owl-nav {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	.nav-btn{
		&.prev-slide{
			&:before{
				margin-left: -30px;
				content:"";
				font-family: "Font Awesome 5 Pro";
				color: #fff;
			}
		}
		&.next-slide{
			&:before{
				margin-right: -30px;
				content:"";
				font-family: "Font Awesome 5 Pro";
				color: #fff;
			}  
		}
	}
}



.side-col{

	&.green-bg{
		padding: 2em;
		border-radius: 10px;
		background: $brand-color;
		p,h1,h2,h3{
			color: #ffffff;
		}
		.btn{
			color: #000;
			background-color: #fff;
		}
		svg{
			fill: $brand-color;
		}
		& ~ svg{
			fill: $brand-color;
		}

	}

	&.specs{
		border-radius: 10px 10px 0 0;
		margin-bottom: 30px;
		&:before{
			transform: rotate(180deg);
			content: url("../assets/svgs/sideCol-clip.svg");
			position: absolute;
			bottom: -35px;
			left: 0;
			width: 100%;
		}
		ul{


			list-style: none;
			padding-left: 0;
			li{
				color: #fff;
				margin-bottom: 10px;
				span{
					&:first-of-type{
						font-weight: 700;
					}
				}
			}
		}
	}

	&.quote{
		border-radius: 0 0 10px 10px;
		margin-top: 30px;
		&:before{
			content: url("../assets/svgs/sideCol-clip.svg");
			position: absolute;
			top: -35px;
			left: 0;
			width: 100%;
		}
		padding: 5em;
		h4{
			color: #fff;
			margin-bottom: 1em;
		}
		form{
			margin-bottom: 20px;
		}
		.form-group{
			margin-bottom: 10px;
		}
		.form-control {
			background-color: transparent;
			background-clip: padding-box;
			border: 1px solid #ffffff; 
			opacity: 1;
			border-radius: 3px;
			height: calc(1.5em + .75rem + 8px);
			color: #fff;
			&::placeholder{
				color:#fff;
			}
		}

		.promo {
			display: flex;
			justify-content: center;
			text-align: center;
			.circle{
				width: 150px;
				height: 150px;
				border-radius: 100%;
				position: absolute;
				z-index: 1;
				top: -75px;
				color: #fff;
				text-align: center;
				padding: 20px;
				background-color: #000;
				span{
					@include heading-bold;
					line-height: 1;
					font-size: 40px;
					display: block;
					color: #fff;
				}
			}

		}

	}
}



@include media-breakpoint-down(md) {
	.side-col{
		margin-left: 15px;
		margin-right: 15px;

		&.quote{
			margin-top: 4em;
			padding: 5em 2em;

			.promo{
				.circle{ 
					width: 130px;
					height: 130px;
					span{
						font-size: 32px;
					}
				}
			}
		}
	}
	#carousel-gallery {
		.carousel-inner {
			.carousel-item{
				height: 500px;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	
	#carousel-gallery {
		.carousel-inner {
			.carousel-item{
				height: 300px;
			}
		}
	}
}