//---- Nav
@import "_structure/_navigation-structure";



$bg-color: $brand-color; 
$nav-link-color: #fff;

$link-alignment: flex-end;

.header-wrap{
	background-color: #000;
	transition: opacity 0.2s ease-in-out, top 0.2s ease-in-out;
	top: 0;
	opacity: 1;

	.navbar-brand{
		padding-top: 0;
		margin-top: -20px;
		display: block;
		position: absolute;
		a{
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	&.color-nav{
		background-image: none;
		background-color: #ffffff;
		.navbar-brand{
			background-image: url('../assets/system/logo-red.png')
		}
		.main-nav.navbar.navbar-default {
			.navbar-nav {
				li{
					& > a{
						&:not(.btn){
							color: #ADAFAF;
							&:hover{
								color: $brand-color;
							}
						}
					}
				}
			}
		}
		.main-nav-trigger {
			.main-nav-icon{
				background: #888;
				&:after, 
				&:before{
					background: #888;
				}
			}
		}
	}
}

.main-nav.navbar.navbar-default {
	min-height: 0;
	background-color:transparent;
	z-index:3;

	.color-nav &{
		@include link-color(#ADAFAF, $brand-color);
	}

	.navbar-header{
		width: 100%;
	}

	.navbar-nav{
		width: 100%;
		justify-content: space-between;
		flex-direction:row;
		.btn.btn-primary{
			font-size: 12px;
			color: $brand-color;
			background-color: #fff;
			&:hover{
				color: #fff;
				background-color: $brand-color;
			}
		}
		& > li{
			&:nth-child(1){
				& > a:not(.btn) {
					padding: 10px 15px 10px 0;
				}	
			}
		}
		li {
			position: relative;
			@include font-lex-regular;
			font-size: 12px;
			transition: opacity 0.2s ease-in-out;
			
			&.active{
				& > a:not(.btn){
					background: transparent;
				}

				&::after{
					content: '';
					width: 100%;
					position: absolute;
					height: 4px;
					background: #ffffff;
				}
			}

			& > a:not(.btn) {
				
				color: $nav-link-color;
				padding: 10px 15px;
				transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
				opacity: hidden;
				display: block;

				&:hover,
				&:focus,
				&:active {
					color: rgba(255,255,255,.7);
					background-color: transparent;
				}	
			}
		}
		&.opened{
			li:not(.open){
				opacity: 0.2;
			}

		}
	}
}


@include media-breakpoint-up(md) {

	.main-nav.navbar.navbar-default {

		.dropdown{
			.dropdown-menu{
				&.open{
					display: block;
				}
			}
		}


		.navbar-nav {

			flex-wrap:wrap;



		}
		.navbar-brand {
			width: 175px;
			transition: all 0.1s;
			margin-top: 20px;
			position: absolute;
			margin-top: 30px;
			padding: 0;
			height: auto;
			z-index: 9;
		}

	}
}




@include media-breakpoint-down(lg) {

	.main-nav.navbar.navbar-default {
		padding: .5rem 0rem;
		.navbar-nav{
			.btn.btn-primary{
				font-size: 11px;
			}
			li {
				

				& > a:not(.btn) {
					padding: 10px 5px;
				}
			}
		}
	}
}


@include media-breakpoint-down(sm) {

	.main-nav.navbar.navbar-default {
		.dropdown-menu{
			.card{
				padding:0;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.header-wrap{
		top: 0px;
		transition: top 0.2s ease-in-out;
		&.shrink{
			background-image: none;
			background-color: #ffffff;
			.navbar-brand{
				background-image: url('../assets/system/logo-red.png');
				margin-bottom: 15px;
			}
			.main-nav.navbar.navbar-default {
				.navbar-nav {
					li{
						& > a{
							&:not(.btn){
								color: #ADAFAF;
								&:hover{
									color: $brand-color;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {

	.header-wrap{
		width: 100%;
		background-color: transparent;
		z-index: 9;
	}

	.navbar-brand {
		width: 116px;
	}

	.page-wrapper {
		position: relative;
		z-index: 2;
		transform: translateZ(0);
		transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
	}

	.main-nav.navbar.navbar-default {
		background-color: $brand-color;


		.main-navigation-wrapper {
			padding: 65px 50px;
			transform: translateX(0%);
			transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
		}

		.navbar-nav {
			flex-direction: column;

			& > li{
				transform:translateY(15px);
				opacity: 0;
			}
			li {
				&:nth-child(1){
					& > a:not(.btn) {
						padding: 10px 5px 10px 0;
					}	
				}
				float: none;
				font-size: 18px;
				position: relative;
				padding-left: 20px;



				transition: transform 0.2s cubic-bezier(0.91, 0.01, 0.6, 0.99), opacity 0.2s cubic-bezier(0.91, 0.01, 0.6, 0.99) ;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}n) {
						transition-delay: #{$i * 0.1}s;
					}
				}
				
				a:not(.btn){
					color: #fff;
					&:active,
					&:hover,
					&:focus,
					&:hover:before,
					&:focus:before {
						color: #000;
						background: transparent;
					}
				}
				&:before{
					content:'';
					position: absolute;
					left: 0;
					top: 23px;
					transform:translateY(-50%);
					display: inline-block;
					background-color: #fff;
					height: 1px;
					width: 15px;
				}
			}

			.dropdown-menu{
				top: 0;
				padding: 0 50px;
				margin-top: 65px;
				li{
					&:before{
						display: none;
						padding: 0;
					}
				}
				&.show{
					-ms-transform: translateX(0%);
					transform: translateX(0%);
				}
			}

		}
	}

	.main-nav-trigger {
		right: 15px;
		top: 20px;
		height: 25px;
		width: 30px;
		transition: transform 0.2s;

		.main-nav-icon {

			@include center;
			width: 30px;
			height: 2px;

			background-color: #fff;
			cursor: pointer;
		}
	}

	.navigation-is-open{
		overflow: hidden;
		.main-nav.navbar.navbar-default {

			.navbar-nav {
				& > li{
					opacity: 1;
					transform:translateY(0px);
				}
				li{

					&.phone-number{
						position: absolute;
						bottom: 50px;
						left: 0;
						width: 100%;
						@include font-light;
						a{
							color: #ADAFAF;
							&:hover, 
							&:focus{
								color: $brand-color;
							}
						}
						&:before{
							display: none;
						}
					}
				}
			}
		}
		.header-wrap{
			.main-nav-trigger {
				.main-nav-icon{
					background: transparent;
					&:after, 
					&:before{
						background: #fff;
					}
				}
			}

		}
	}
}

.dropdown-backdrop{
	z-index: 0;
	display: none;
}
