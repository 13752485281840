////
//Variables
////

//color variables
$darker-grey :#d2d0d0;
$grey-text: rgba(33, 43, 54, 0.4);
$lighter-grey : #EEEEEC;

$linkedin-color:#0077B5;
$twitter-color:#2CAAE1;


$brand-color: #154734;
$brand-highlight: #000;
$brand-grey: #ADAFAF;

$link-color: $brand-color;
$link-highlight: darken($brand-color, 10%);

$error-color: #E6302F;

//fonts
@import url("https://use.typekit.net/oqj6und.css");

@mixin fontawesome-light(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 300;

}
@mixin fontawesome-solid(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
}
@mixin fontawesome-regular(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400;
}
@mixin fontawesome-brand(){
	font-family: "Font Awesome 5 Brands ";
	font-style: normal;
	font-weight: 400;
}


@mixin font-light(){
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	letter-spacing: 0.2px;
}
@mixin font-regular(){
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}
@mixin font-bold(){
	font-family: 'Lato' ,  Helvetica, Arial, sans-serif;
	font-weight: 700;
}

@mixin font-black(){
	font-family: 'Lato' , Helvetica, Arial, sans-serif;
	font-weight: 800;
}

@mixin heading-regular(){
	font-family: 'Martel', serif, Georgia, Times, Times New Roman ;
	font-weight: 600;
}
@mixin heading-bold(){
	font-family: 'Martel', serif, Georgia, Times, Times New Roman;
	font-weight: 700;
}
@mixin font-lex-regular(){
	font-family: 'Lexend Exa', serif, Georgia, Times, Times New Roman;
	font-weight: 400;
}
@mixin font-lex-bold(){
	font-family: 'Lexend Exa', serif, Georgia, Times, Times New Roman;
	font-weight: 700;
}




//structural variables

$animation-duration: 0.7s;