.map-selector{
	ul{
		display: inline-block;
		margin-top: 50px;

		li{
			list-style: none;
			float: left;
			margin-right: 5px;
			margin-bottom: 10px;
			a {
				position: relative;
				height: 100px;
				width: 100px;
				border: 1px solid #000;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				color: #000;
				opacity: 1;
				&:after {
					opacity: 0;
					content: "";
					height: 100%;
					width: 100%;
					position: absolute;
					background-color: rgba(0, 0, 0, 0.2);
				}
				&:hover{
					color: #fff;
					&:after {
						opacity: 1;
					}
					img{
						opacity: 1;
					}
				}
				img{
					opacity: 0;
					position: absolute;
					object-fit: cover;
					height: 100%;
					width: 100%;
					transition: opacity 0.15s ease-out;
				}
				.location{
					position: relative;
					z-index: 2;
					text-align: center;
					line-height: 1.2;
					font-size: 12px;
					span{
						font-size: 20px;
						font-weight: 600;
						width: 100%;
						display: block;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.fa-ul{
		margin-top: 30px;
		li{
			margin-bottom: 20px;
			font-size: 1.2em;
			&.hover{
				a{
					color: $brand-color;
				}
			}
		}
		.fa-li{
			.far{

				font-size: 1.6em;
			}
		}
	}
	
	a{
		color: $brand-color;
		&:hover,
		&:focus{
			color: $brand-highlight;
		}

	}
}

#northland , #hawkesbay, #auckland, #waikato, #bayofplenty, #gisborne, #taranaki, #manawatuwhanganui, #wellington, #nelsontasman, #marlborough,#westcoast, #canterbury, #otago, #southland{
	cursor: pointer;
	fill: rgb(131, 155, 142);
}

#northland{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#northland-url");
		}
	}
}


#auckland{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#auckland-url");
		}
	}
}

#waikato{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#waikato-url");
		}
	}
}

#bayofplenty{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#bayofplenty-url");
		}
	}
}

#gisborne{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#gisborne-url");	
		}
	}
}

#hawkesbay{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#hawkesbay-url");
		}
	}
}

#taranaki{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#taranaki-url");	
		}
	}
}

#manawatuwhanganui{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#manawatuwhanganui-url");	
		}
	}
}

#wellington{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#wellington-url");	
		}
	}
}

#nelsontasman{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#nelsontasman-url");	
		}
	}
}

#marlborough{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#marlborough-url");	
		}
	}
}

#westcoast{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#westcoast-url");	
		}
	}
}

#canterbury{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#canterbury-url");	
		}
	}
}

#otago{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#otago-url");	
		}
	}
}

#southland{
	&:hover,
	&.hover{
		path,
		polygon{
			fill:url("#southland-url");	
		}
	}
}


// $regions : ( "northland" , "hawkesbay", "auckland", "waikato", "bayofplenty", "gisborne", "taranaki", "manawatuwhanganui", "wellington", "nelsontasman", "marlborough", "westcoast", "canterbury", "otago", "southland");

// @each $val in $regions{
// 	.#{$regions}{
// 		##{$regions}{
// 			path,
// 			polygon{
// 				fill:$brand-highlight;
// 			}
// 		}
// 	}
// }


.map-container{
	.region{
		path,
		polygon{
			cursor: default;
			transition: fill 0.1s ease-in-out;
		}
		&.hover,
		&:hover,
		&:focus{
			path,
			polygon{
				fill:$brand-highlight;
			}
		}
		
	}

}
