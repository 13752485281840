#contact-form {
	label {
		font-size: 14px;
		color: #fff;
	} 
	input{
		font-size: 14px;
		&::placeholder{
			font-size: 14px;
		}
	}
	.text-muted {
		color: rgba(255, 255, 255, 0.5)!important;
	}
}   