#home-carousel{
	position: relative;
	margin-bottom: -100px;
	.carousel-inner{
		height: 100%;

		.carousel-item{
			height: 650px;
			background: no-repeat center center scroll;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 34%, rgba(0,0,0,0.70) 100%);
				border: 1px solid #979797;
			}
		}
	}
	.carousel-caption{
		bottom: 80px;
		.carousel-heading{ 

		}
		h1{
			font-size: 54px;
		}

		.carousel-subheading {
			p{
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include heading-regular;
				color: #fff;
				.location-pin{
					i{
						font-size: 20px;
					}
				}
			}
		}
	}

}



.quote-carousel{
	position: relative;
	.carousel-inner{
		padding-top: 20px;
		padding-bottom: 50px;
		blockquote{
			padding: 60px 0;
		}
		h6{
			position: absolute;
			top: 0px;
			left: 50%;
			transform:translateX(-50%);
		}
		.item{
			padding-top: 25px;
		}
	}
	.carousel-control{
		text-shadow:none;
		
		font-size: 30px;
		font-weight: bold;
		height: 30px;
		width: 30px;
		bottom:80px;
		top: initial;



		&.right,
		&.left{
			background-image: none;
			color: $brand-color;
			border:1px solid $brand-color;
			border-radius: 50%;
			font-size: 1em;
			display: flex;
			align-items:center;
			justify-content:center;
			transition:background-color 0.2s ease-in-out;
			&:hover,
			&:focus{
				border:$brand-color;
				color: #fff;
				background:$brand-color;
			}
		}

		&.left{
			left: 46%;
			transform:translateX(-54%);
		}
		&.right{
			left:54%;
			transform:translateX(-54%);
		}


	}
}


main{
	.carousel-inner{
		.item{
			height: 0;
			padding-bottom: 56.5%;
			img{
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform:translate(-50%, -50%);
			}
		}
		.carousel-control{
			.far{
				position: absolute;
				top: 50%;
				transform:tranlsateY(-50%);
			}
		}
	}
}



.carousel-fade{

	.carousel-inner{
		.item {
			transition-property: opacity;
		}
		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}
		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}
		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}

}

@include media-breakpoint-down(lg) {
	#home-carousel{

		h1{
			font-size: 2.4em;
		}

	}
}

@include media-breakpoint-down(md) {

	
	#home-carousel{
		
		.carousel-inner{
			.carousel-item{
				height: 400px;
			}
		}
		.carousel-caption{
			z-index: 1;
			h1{
				font-size: 34px;
			}
		}
	}


}

@include media-breakpoint-down(sm) {
	#home-carousel{
		.carousel-inner{

			.item{

				img{
					height: 100%;
					width: auto;
					max-width: none;
				}
			}
		}
	}
}
