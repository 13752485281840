
.banner-image{
	margin-bottom: 4em;
	.banner-container{
		height: 600px;
		position: relative;
		overflow: hidden;
		-webkit-clip-path: url(#fullBanner);
		clip-path: url(#fullBanner);
		img{
			position: absolute;
			height: 100%;
			width: 100%;
			max-width: none;
			transform: translate(-50%,-50%);
			left: 50%;
			top: 50%;
			-o-object-fit: cover;
			object-fit: cover;
			// clip-path: url("#bannerPath");
		}	
	}

}

.back-top{
	i{
		margin-right: 5px;
		font-size: 18px;
	}
}

.places-col{

	background-image: url("../assets/regions/map-bg.png");
	padding: 2em;
	border-radius: 10px;
	background-color: $brand-color;
	margin-top: -300px;
	p,h1,h2,h3{
		color: #ffffff;
	}
	.btn{
		color: $brand-color;
		background-color: #fff;
	}


}


ol.places-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 60px;
	column-count:2;
	li{
		padding-bottom: 20px;
		overflow: hidden;
		counter-increment: step-counter;
		position: relative;
		padding-left: 40px;

		a{
			color: #fff;
			&:hover{
				color: #000;
			}
		}

		&::before{
			content: counter(step-counter);
			border-radius: 60px;
			font-size: 16px;
			width: 30px;
			height: 30px;
			background: #fff;
			color: #000;
			display: block;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			@include heading-regular;
		}
		&:after{

		}
	}
}

@include media-breakpoint-down(md) {
	.places-col{

			margin-top: 0;
			margin-bottom:30px;
	}


	.banner-image{
		.banner-container{
			height: 400px;
			-webkit-clip-path: url(#mobileBanner);
			clip-path: url(#mobileBanner);
		}
	}

}
