 
//- Common Page elements



.lead{
	margin-top: 1em;
	line-height: 1.5em;
	margin-bottom: 1em;
	font-size: 1.2em;
	@include font-regular;
}


.lead-heading{
	margin-top: 1em;
	line-height: 1.5em;
	margin-bottom: 1em;
	font-size: 1.2em;
	@include heading-regular;
}

.reveal{ 
	visibility: hidden;
}

.btn.btn-primary,
.btn.btn-default{
	text-transform: uppercase; 
	background-color: $brand-color; 
	border-radius: 5px;
	border: 0;
	padding: 8px 25px;
	transition: background-color 0.2s ease-in-out;
	color: #fff;
	display: inline-block;
	font-size: 14px;

	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-highlight;
		border-color: $brand-highlight;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
	& + .btn-primary{
		margin-left: 10px;
	}
}

ul {
	padding-left: 20px;
	li{

	}
}

.location-pin{
	i{
		margin-right: 5px;
		font-size: 25px;
	}
}

.align-bottom{
	display: flex;
	align-items: flex-end;
}


.img-container{
	max-width: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.5%;

	img,
	iframe{
		position: absolute;
		height: 100%;
		width: 100%;
		max-width: none;
		transform:translate(-50%, -50%);
		left: 50%;
		top: 50%;
		object-fit: cover;
	}

	.no-objectfit &{
		img{
			width: auto;

		}
	}
}



.justify-content-evenly{
	justify-content:space-evenly;
}

.warning{
	color: #FA5E3F;
}

.cta{
	h2{
		margin-bottom: 30px;
		font-size: 2.7em;
		font-family: "HelveticaNeue-Light" , Helvetica, Arial, sans-serif;
		font-weight: 300;
		letter-spacing: 0.2px;
	}
}


a{
	&:hover{
		.see-more{
			&:before{
				left: 5px;
			}
		}
	}
}

.see-more{
	position: relative;
	padding-left: 25px;
	@include font-bold;
	color: $brand-color !important;

	
	&:not(a):before{
		transition: left 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		content:'\f30b';
		@include fontawesome-regular();
		position: absolute;
		top: 50%;
		transform:translateY(-50%);
		line-height: 1em;
		left: 0;
	}
	i{
		position: absolute;
		left: 0;
		top: 50%;
		transform:translateY(-50%);
	}
	&:hover{
		left: 5px;
	}
}


.no-hero{
	margin-top: 210px;
	margin-bottom: 100px;
	small{
		color: #ADAFAF;
		& + h1{
			margin-top: 30px;
		}
	}
}




@include media-breakpoint-down(md) {
	.lead-in {
		padding-bottom: 50px;
	}



}


@include media-breakpoint-down(sm) {
	.lead-in {
		padding-bottom: 30px;
	}

	.cta{
		h2 {
			font-size: 2em;
			line-height: 1.3;
		}
	}
	.align-self-end{
		& > img {
			margin-bottom: 40px;
			& + img{
				margin-left: 00px;
			}
		}
	}
}



@include media-breakpoint-up(md) {

}


@media (min-width: 1600px) {

	
	.section{
		
	}

}