.accordion{
	margin-top: 30px;
	.card{
		background: transparent;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.card-header{
		background: transparent; 
		padding: 30px 0 15px;
		border-top: 1px #000 solid;
		border-bottom: 0;
		border-radius: 0;

		
		.collapsed{
			h3{
				&:after{
					content: "";
				}
			}

		}

		h3{
			position: relative;
			margin-bottom: 0.5em;
			margin-top: 0;

			width: 100%;
			&:after{
				content: "";
				font-family: "Font Awesome 5 Pro";
				font-weight: 200;
				color: $brand-color;
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		
		h3{
			color: $brand-highlight;
			&:hover{
				color: $brand-color;
			}
		}
	}
	.card-body{
		padding: 0;
	}
	.collapse{
		overflow: hidden;
	}
}



@include media-breakpoint-down(md) {
	
	.img-accordion{
		.images{
			overflow: hidden;
			height: 400px;
			margin-bottom: 30px;
			.img-container{
				height: 100%;
			}
		}
	}

}

@include media-breakpoint-down(xs) {
	.img-accordion{
		.images{
			height: 250px;

		}
	}
}